<template>
  <div v-if="isshowgrid">
    <va-card title="User List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <va-select
              v-if = "isCreate"
              label="Role *"
              placeholder="Select Role"
              v-model="role"
              textBy="id"
              :options="role_obj"
              :error="!!roleErrors.length"
              :error-messages="roleErrors"
              noClear
            />
            <va-input
              v-if = "isUpdate"
              v-model="role"
              type="text"
              label="Role"
              :disabled="true"
            />
            <va-input
              v-if = "isCreate"
              v-model.trim="user_name"
              type="text"
              label="User Name *"
              placeholder="Enter User Name"
              pattern="^[a-z0-9]+$"
              :error="!!userNameErrors.length"
              :error-messages="userNameErrors"
            />
            <va-input
              v-if = "isUpdate"
              v-model="user_name"
              type="text"
              label="User Name"
              :disabled="true"
            />
            <va-input
              v-model.trim="password"
              type="password"
              label="Password *(Minimum length should be 8)"
              placeholder="Enter Password"
              pattern="[A-Za-z0-9_@!./#&+-^]{8,}$"
              :error="!!passwordErrors.length"
              :error-messages="passwordErrors"
            />
            <va-input
              v-model.trim="phone_number"
              type="telphone"
              label="Mobile Number *"
              pattern="[6-9]{1}[0-9]{9}"
              maxlength="10"
              placeholder="Enter Mobile Number"
              :error="!!mobileNumberErrors.length"
              :error-messages="mobileNumberErrors"
            />
            <va-checkbox
              v-model="status"
              label="Status"
            />
            <div class="d-flex justify--center mt-3">
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createUser()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateUser()">Update</va-button>
              <va-button type="submit" class="my-0" @click.prevent="list()">Cancel</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
import regex from  '../../i18n/regex_pattern.js'

Vue.use(vueResource)
export default {
  name: 'org',
  beforeCreate () {
    const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + 'user').then(response => {
        loader.hide()
        this.userData = response.body
        this.isshowForm = false
        this.isshowgrid = true
        this.getAllUser()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      })
  },
  data () {
    return {
      term: null,
      perPage: '6',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      showRemoveModal: false,
      status: false,
      createProvision: false,
      last_name: '',
      first_name: '',
      org_name:'',
      role: '',
      password: '',
      user_name: '',
      phone_number: '', 
      title: '',
      orgArr: [],
      userData: [],
      orgNameErrors: [],
      roleErrors:[],
      lastNameErrors: [],
      firstNameErrors: [],
      userNameErrors: [],
      mobileNumberErrors: [],
      passwordErrors: [],
    }
  },
  computed: {
    checkpassword () {
      if (this.password.length >= 8) {
        return true
      } else {
        return false
      }
    },
    formReady () {
      return !this.userNameErrors.length && !this.roleErrors.length && !this.mobileNumberErrors.length
    },
    fields () {
      return [{
        name: 'user_name',
        title: 'User Name',
      },
      {
        name: 'role',
        title: 'Role',
      },
      {
        name: 'phone_number',
        title: 'Phone Number',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.userData)
    },
  },
  methods: {
    getAllUser () {
      var role = this.$cookies.get('userRole') 
      this.$http.get(config.menu.host + 'user').then(response => {
        this.userData = (response.body.length > 0) ? response.body : []
        this.isshowgrid = true
      })
    },
    createUser () {
      this.userNameErrors = this.user_name ? [] : ['User Name is required']
      this.passwordErrors  = this.password ? [] : ['Password is required']
      this.mobileNumberErrors = this.phone_number ? [] : ['Phone Number is required']
      this.roleErrors = this.role ? [] : ['Role is required']

      if (!this.checkpassword) {
        Vue.notify({
          text: 'Password Length must 8 Characters',
          type: 'error',
        })
        return
      }
            if((this.phone_number.length != 10) || !regex.numberRegex(this.phone_number)){
          return Vue.notify({ text: 'Phone Number is not valid!', type: 'error' })
        }

      if (!this.formReady) {
        return
      }
      var payload = {
        user_name: this.user_name,
        role: this.role.id,
        password: this.password,
        status: this.status,
        phone_number:this.phone_number
      }
      console.log(JSON.stringify(payload))
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + 'user', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateUser () {
      this.mobileNumberErrors = this.phone_number ? [] : ['Phone Number is required']
      if (this.password &&  !this.checkpassword) {
        Vue.notify({
          text: 'Password Length must 8 Characters',
          type: 'error',
        })
        return
      }
      if (!this.formReady) {
        return
      }
      var payload = {
        user_name: this.user_name,
        role: this.role,
        last_name: this.last_name,
        first_name: this.first_name,
        phone_number: this.phone_number,
        status: this.status,
      }
      if (this.password) {
        payload['password'] = this.password
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + 'user/' + this.user_id, payload).then(resp => {
        loader.hide()
        if (resp && resp.body) {
          Vue.notify({ text: resp.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    add () {
      this.role_obj = [{id:'CONTENT_PROVIDER'}] 
      this.password = ''
      this.title = 'Create User'
      this.user_name = ''
      this.first_name = ''
      this.last_name = ''
      this.org_name = ''
      this.role = ''
      this.phone_number = ''
      this.city = ''
      this.status = true
      this.set_moq = false
      this.free_credit = false
      this.sub_operators = false
      this.enable_vod = false
      this.ad_provision = false

      this.userNameErrors = []
      this.firstNameErrors = []
      this.lastNameErrors = []
      this.passwordErrors = []
      this.mobileNumberErrors = []
      this.roleErrors = []

      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    edit (row) {
      this.title = 'Update User'
      this.user_id = row.user_id
      this.first_name = row.first_name
      this.last_name = row.last_name
      this.user_name = row.user_name
      this.role = row.role
      this.password = ''
      this.phone_number = row.phone_number
      this.status = row.status ? true : false

      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllUser()
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
